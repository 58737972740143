import React from 'react'
import tw from 'tailwind.macro'
import styled from 'styled-components'
import { screen } from '../../../../utils'
import SmartLink from '../../../General/SmartLink'
import { md, sm } from '../../../../../tailwind.screens'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.min.css'

const Container = styled.div`
  font-family: 'Inter', sans-serif;
  background: #000;

  ${tw`
    relative
  `}
`

const ContainerInner = styled.div`
  padding: 0;
  max-width: 1360px;
  padding-bottom: 130px;
  position: relative;

  ${screen('md')} {
    padding-top: 236px;
    padding-bottom: 107px;
    padding-left: 25px;
    padding-right: 25px;
  }

  ${screen('lg')} {
    padding-left: 40px;
    padding-right: 40px;
  }

  ${tw`
    mx-auto
    my-0
  `}
`

const ImageContainer = styled.div`
  ${tw`
    md:absolute
  `}

  ${screen('md')} {
    right: 0;
    top: 0;
  }

  ${screen('md')} {
    margin: 0:
  }

  img {
    ${screen('md')} {
      margin: 0;
    }
    ${tw`
      w-full
    `}
  }
`

const Button = styled.div`
  height: 70px;
  border: 1px solid #76f935;
  letter-spacing: -0.03em;
  color: #76f935;
  padding: 0 22px;
  font-size: 22px;
  line-height: 24px;

  ${screen('md')} {
    font-size: 24px;
    line-height: 29px;
  }

  &:hover {
    background: #76f935;
  }

  ${tw`
    inline-flex
    font-semibold
    items-center
    justify-center
    cursor-pointer
    md:w-full
    hover:text-white
  `}

  ${screen('md')} {
    ${tw`
      w-auto
    `}
  }
`

const TopContainer = styled.div`
  padding: 0 25px;
  ${tw`
    flex
    relative
    flex-col
    md:p-0
  `}
`

const CenterContainer = styled.div`
  ${tw`
    flex
    relative
    flex-col-reverse
  `}
  padding-top: 68px;
  padding-bottom: 39px;

  ${screen('md')} {
    padding-top: 45px;
    padding-bottom: 47px;
    ${tw`
      flex-row
    `}
  }
`

const Tag = styled.h2`
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.01em;
  color: #77f835;
  padding-bottom: 10px;

  ${screen('md')} {
    font-size: 20px;
    line-height: 24px;
  }
`

const Title = styled.h2`
font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 35px;
  line-height: 35px;
  letter-spacing: -0.01em;
  padding-bottom: 24px;


  ${screen('md')} {
    font-weight: 700;
    font-size: 50px;
    line-height: 50px;
    padding-bottom: 16px;
`

const Description = styled.p`
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: #cdcdcd;

  ${screen('md')} {
    font-weight: 600;
    font-size: 22px;
    line-height: 32px;
    color: #cdcdcd;
  }
`

const Subtitle = styled.h3`
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.01em;
  padding: 0 60px;

  ${tw`
    text-center
    uppercase
    font-semibold
  `}

  ${screen('md')} {
    padding: 0;
  }

  ${screen('md')} {
    text-align: left;
  }
`

const ProjectIconContainer = styled.div`
  display: inline-flex;
  background: white;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: solid 1px white;
  transform-style: preserve-3d;
  transition: transform 0.8s;
  height: 221px;
  width: 221px;

  ${screen('md')} {
    height: 160px;
    width: 160px;
  }
`

const ProjectIcon = styled.img`
  max-width: 110px;
  object-fit: scale-down;
  max-height: 110px;
  min-height: 110px;
  min-width: 110px;
  backface-visibility: hidden;
  position: absolute;
`

const ProjectIconColor = styled.img`
  max-width: 110px;
  object-fit: scale-down;
  max-height: 110px;
  min-height: 110px;
  min-width: 110px;
  transform: rotateY(180deg);
  backface-visibility: hidden;
  position: absolute;
`

const BottomContainer = styled.div`
  margin-bottom: 50px;

  ${tw`
    p-0
    flex
    relative
    items-center
    flex-row
    flex-wrap
    w-full
  `}
  ${screen('md')} {
    margin-bottom: 109px;
    ${tw`
      justify-between
      mx-auto
    `}
  }
`

const Project = styled.div`
  &:first-of-type {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }

  a {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  text-align: center;

  ${screen('md')} {
    margin: 0 14px;
    width: unset;
    text-align: unset;
  }

  &:hover {
    a div {
      transform: rotateY(180deg);
    }
    a p {
      color: #76f935;
    }
  }
`

const ProjectName = styled.p`
  transition: color ease 0.8s;
  margin-top: 21px;
  padding-bottom: 36px;
  font-weight: 700;
  font-size: 20px;
  line-height: 24.2px;

  ${tw`
    text-center
    xl:pb-0
  `}
`

const CtaContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  padding: 0 25px;

  ${tw`
    lg:p-0
  `}
`

const defaultParams = {
  centeredSlides: false,
  navigation: false,
  spaceBetween: 0,
  loop: false,
  autoplay: false,
  slidesPerView: 1,
  cache: false,
  slidesOffsetAfter: 0,
  observer: true,
  observeParents: true,
  watchSlidesVisibility: true,
  pagination: {
    el: '.swiper-dots-project',
    type: 'bullets',
    clickable: true,
  },
  breakpoints: {
    [1330]: {
      spaceBetween: 80,
      slidesPerView: 5,
    },
    [1090]: {
      spaceBetween: 80,
      slidesPerView: 4,
    },
    [parseInt(md)]: {
      spaceBetween: 60,
      slidesPerView: 3,
    },
    [parseInt(sm)]: {
      spaceBetween: 40,
      slidesPerView: 2,
    },
  },
  onAfterInit(swiper) {
    swiper.update()
  },
}

const SwiperDots = styled.div`
  ${tw`
    flex
    w-full
    justify-center
    flex
    xl:hidden
    items-center
  `}

  .swiper-pagination-bullet {
    height: 6px;
    width: 6px;
    margin: 0 2px;
    border-radius: 50%;
    background-color: #cdcdcd;
    margin: 0 5px;

    &.swiper-pagination-bullet-active {
      background: #77f835;
      opacity: 1;
      height: 8px;
      width: 8px;
    }
  }
`

function Ecosystem({
  tag,
  title,
  description,
  image,
  subtitle,
  ctaText,
  ctaLink,
  projects,
}) {
  const instanceParams = defaultParams

  return (
    <Container>
      <ImageContainer>
        <img src={image} alt={title || tag || description} />
      </ImageContainer>
      <ContainerInner>
        <TopContainer>
          <div className="w-full lg:w-1/2 text-white uppercase">
            <Tag>{tag}</Tag>
          </div>
          <div className="w-full lg:w-1/2 text-white">
            <Title>{title}</Title>
          </div>
          <div className="w-full lg:w-2/5 text-white">
            <Description>{description}</Description>
          </div>
        </TopContainer>

        <CenterContainer>
          <div className="w-full lg:w-1/2 text-white">
            <Subtitle>{subtitle}</Subtitle>
          </div>
        </CenterContainer>

        {projects && projects.length > 0 ? (
          <BottomContainer>
            <Swiper {...instanceParams} className="swiper-ecosystem">
              {projects.map((item) => (
                <SwiperSlide key={`project_${item.id}`}>
                  <Project>
                    <SmartLink to={item.link || '#'}>
                      <ProjectIconContainer
                        style={{ backgroundColor: item.bg }}
                      >
                        <ProjectIcon src={item.icon} />
                        <ProjectIconColor src={item.iconColor || item.icon} />
                      </ProjectIconContainer>
                      <ProjectName className="text-white">
                        {item.name}
                      </ProjectName>
                    </SmartLink>
                  </Project>
                </SwiperSlide>
              ))}
            </Swiper>
            <SwiperDots className="swiper-dots-project" />
          </BottomContainer>
        ) : null}
        <CtaContainer>
          <SmartLink
            className="w-full lg:w-auto no-underline text-center"
            to={ctaLink}
          >
            <Button>
              <span>{ctaText}</span>
            </Button>
          </SmartLink>
        </CtaContainer>
      </ContainerInner>
    </Container>
  )
}

export default Ecosystem
