import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { screen } from '../../../../utils'
import LinkArrow from '../../../General/LinkArrow'
import bgVideo from '../../../../videos/algorand-mantle-animatiom-16-9_algorand-home.mp4'
import bgVideoMobile from '../../../../videos/algorand-mantle-animation-tablet-portrait_algorand-home.mp4'

const Container = styled.div`
  position: relative;
  max-width: 1400px;
  min-height: 400px;

  @media (min-width: 640px) and (max-width: 768px) {
    min-height: 360px;
  }

  ${screen('md')} {
    min-height: 619px;
  }

  ${screen('xl')} {
    padding: 240px 0 150px;
  }

  @media (max-width: 768px) and (min-width: 576px) {
    padding-top: 0;
  }
  @media (max-width: 1200px) and (min-width: 768px) {
    padding-top: 100px;
  }

  box-sizing: border-box;

  ${tw`
    relative
    w-full
    mx-auto
  `}
}
`
const Inner = styled.div`
  position: relative;
  padding: 86px 0 0;
  ${tw`
    text-xl
  `}

  ${screen('md')} {
    padding: 0 0 0 40px;
    max-width: 1360px;
    margin: 0 auto;
  }
`
const TextContainer = styled.div`
  z-index: 10;
  position: relative;
  padding: 0 25px;

  @media (max-width: 576px) {
    padding-bottom: 290px;
  }

  ${tw`
    lg:p-0
  `}
`

const TitleContainer = styled.div`
  padding-right: 16px;
  z-index: 100;
`

const Title = styled.h1`
  font-family: 'Inter', sans-serif;
  font-size: 36px;
  line-height: 40px;
  letter-spacing: 0.01em;
  font-family: inter;
  font-weight: 700;
  text-align: center;
  margin: auto;

  ${screen('md')} {
    font-size: 65px;
    line-height: 72px;
    z-index: 10;
    max-width: 825px;
  }
`
const DescriptionContainer = styled.div`
  p {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: #cdcdcd;
    padding: 16px 0;
    margin: auto;
    text-align: center;

    ${screen('md')} {
      max-width: 600px;
      padding-bottom: 20px;
      padding-top: 24px;
      line-height: 32px;
      font-size: 22px;
    }
  }
`

function Hero({ title, description, ctaText, ctaLink, ctaColor }) {
  return (
    <div>
      <video
        src={bgVideo}
        muted
        loop
        autoPlay
        playsInline
        className="w-full h-full absolute pin hidden lg:block"
        style={{ objectFit: 'cover' }}
      />
      <video
        src={bgVideoMobile}
        muted
        loop
        autoPlay
        playsInline
        className="w-full h-full absolute pin lg:hidden"
        style={{ objectFit: 'cover' }}
      />
      <Container>
        <Inner>
          <TextContainer>
            <TitleContainer>
              <Title>{title}</Title>
            </TitleContainer>
            <DescriptionContainer
              dangerouslySetInnerHTML={{ __html: description }}
            ></DescriptionContainer>
            {ctaText ? (
              <div className="text-center">
                <LinkArrow text={ctaText} href={ctaLink} color={ctaColor} />
              </div>
            ) : null}
          </TextContainer>
        </Inner>
      </Container>
    </div>
  )
}

export default Hero
