import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { v4 as uuidv4 } from 'uuid'

// components
import PageWrap from '../../Layouts/Global/PageWrap'
import Ecosystem from './Ecosystem'
import ImageAndTextBlock from '../../General/ImageAndTextBlock'
import HomepageSlider from './HomepageSlider'
import Hero from './Hero'

// assets
import '../../../../static/homepagev4/fonts.css'
import '../../../../static/fonts/fonts.css'

const backgrounds = {
  desktop: null,
  mobile: null,
  mobile2x: null,
}

const HomeContainer = styled.div`
  ${tw`
    bg-black
    bg-center
    bg-cover
`}
`

const renderComponent = (component) => {
  const { primary, items } = component

  switch (component.slice_type) {
    case 'slider': {
      return (
        <HomepageSlider
          title={primary.title ? primary.title.text : null}
          items={items.map((item) => ({
            id: uuidv4(),
            stretchOnMobile: item?.stretch_image_on_mobile,
            image: item?.image?.url,
            title: item.title ? item.title.text : null,
            date: item.date ? item.date.text : null,
            ctaLink: item.link?.url,
            topTextColor: item?.top_text_color,
            bottomTextColor: item?.bottom_text_color,
            bottomTextColorTablet: item?.bottom_text_color_tablet,
            topBackgroundColor: item?.top_background_color,
            bottomBackgroundColor: item?.bottom_background_color,
            headingText: item.heading ? item.heading.text : null,
          }))}
        />
      )
    }
    case 'imageand_textblock': {
      return (
        <ImageAndTextBlock
          smallText={
            primary.small_text_heading ? primary.small_text_heading.text : null
          }
          title={primary.title ? primary.title.text : null}
          description={primary.description ? primary.description.text : null}
          ctaText={primary.cta_text ? primary.cta_text.text : null}
          ctaLink={primary?.cta_link?.url}
          image={primary?.image?.url}
          background={primary?.background_color}
          reverse={!!primary?.reverse}
          smallTextColor={primary?.small_text_color}
          titleColor={primary?.title_color}
          ctaColor={primary?.cta_color}
          descriptionColor={primary?.description_color}
          isPortrait={primary?.the_image_is_a_portrait}
        />
      )
    }
    case 'hero': {
      return (
        <Hero
          ctaLink={primary?.cta_link?.url}
          ctaText={primary.cta_text ? primary.cta_text.text : null}
          title={primary.title ? primary.title.text : null}
          description={primary.description ? primary.description.html : null}
          image={primary?.image?.url}
          imageMobile={primary?.mobile_image?.url}
          ctaColor="#77F835"
        />
      )
    }
    case 'ecosystem': {
      return (
        <Ecosystem
          title={primary.title?.text}
          tag={primary.small_text_heading?.text}
          description={primary.description?.text}
          ctaText={primary.cta_text?.text}
          subtitle={primary.projects_list_title?.text}
          projects={
            items &&
            items.length &&
            items.map((project) => {
              return {
                id: uuidv4(),
                name: project.name?.text,
                icon: project.icon && project.icon.url,
                iconColor: project.icon_color && project.icon_color.url,
                link: project.link && project.link.url,
                bg: project.background?.toLowerCase() || 'white',
              }
            })
          }
          image={primary?.image?.url}
          ctaLink={primary?.cta_link?.url}
        />
      )
    }

    default:
      return null
  }
}

const HomepageV6 = ({ seo, data }) => {
  if (!data || !data.body) return null
  const components = data.body

  return (
    <PageWrap
      seo={seo}
      backgrounds={backgrounds}
      bg={false}
      darkMode
      disablePadding
      className="relative"
    >
      <HomeContainer className="-mb-12 lg:-mb-32 pb-8 md:pb-10 text-white">
        {components && components.length > 0
          ? components.map((el, index) => {
              const component = renderComponent(el)
              if (!component) return null
              return <div key={`component_${index}`}>{component}</div>
            })
          : null}
      </HomeContainer>
    </PageWrap>
  )
}

export default HomepageV6
