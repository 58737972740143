import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { screen } from '../../../../utils'
import { sm, xl } from '../../../../../tailwind.screens'
import SmartLink from '../../../../components/General/SmartLink'

const SlideContainer = styled.div`
  ${tw`
    flex
    flex-col
    relative
  `}

  @media (max-width: 576px) {
    .slide-bottom-block {
      background: black !important;
    }
    .slide-title-text {
      color: white !important;
    }
  }

  &.tablet-small {
    @media (min-width: ${sm}) and (max-width: ${xl}) {
      height: 630px;

      .slide-bottom-block {
        ${tw`
          flex
          justify-between
        `}
      }

      .slide-top-block,
      .slide-bottom-block {
        padding: 20px;
      }

      .slide-image {
        max-height: 100%;
        object-fit: ${(props) =>
          props.stretchOnMobile ? 'cover' : 'scale-down'};
      }
    }
  }

  &.tablet-big {
    @media (min-width: ${sm}) and (max-width: ${xl}) {
      height: 630px;

      .slide-title-text,
      .slide-date-text {
        color: ${(props) =>
          props.bottomTextColorTablet ||
          props.bottomTextColor ||
          '#000'} !important;
      }

      .slide-image {
        ${tw`
          absolute
          h-full
          w-full
        `}
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        object-fit: ${(props) => (props.stretchOnMobile ? 'cover' : 'none')};
        max-height: ${(props) => (props.stretchOnMobile ? '100%' : '80%')};
      }

      .slide-top-block,
      .slide-bottom-block {
        padding: 20px;
      }

      .slide-title-text {
        padding: 0px 50px 17px 0;
      }
    }
  }

  &.desktop-small {
    ${screen('xl')} {
      height: 630px;

      .slide-bottom-block {
        ${tw`
          flex
          justify-between
        `}
      }

      .slide-top-block,
      .slide-bottom-block {
        padding: 20px;
      }
    }
  }

  &.desktop-big {
    ${screen('xl')} {
      height: 630px;

      .slide-image {
        ${tw`
          absolute
          h-full
          w-full
        `}
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        object-fit: cover;
      }

      .slide-top-block,
      .slide-bottom-block {
        padding: 20px;
      }

      .slide-title-text {
        padding: 0px 50px 17px 0;
      }
    }
  }
`

const Top = styled.div`
  ${tw`
  `}
`
const Bottom = styled.div`
  ${tw`
    flex
    flex-col
    justify-end
    h-full
  `}
`

const Image = styled.img`
  ${tw`
    max-w-full
    max-h-full
    mx-auto
    block
    w-full
    xl:w-auto
  `}
  z-index: 1;
`
const Title = styled.h3`
  ${tw`
    z-10
    font-bold
    text-xl
    sm:text-25px
    md:text-30px
    font-inter
  `}
  line-height: 25px;
  padding-top: 29px;

  ${screen('sm')} {
    ${tw`
      pt-0
    `}
    line-height: 30px;
  }

  ${screen('md')} {
    line-height: 35px;
  }
`
const Date = styled.p`
  ${tw`
    z-10
    font-bold
    text-xl
    font-inter
  `}
  line-height: 24.2px;

  @media (max-width: ${sm}) {
    ${tw`
      absolute
    `}
    top: 17px;
    left: 20px;
  }
`
const Heading = styled.div`
  ${tw`
    absolute
    z-10
    font-bold
    text-xl
    font-inter
    hidden
    sm:block
  `}
  line-height: 24.2px;
`

function Slide({
  id,
  customClass,
  title,
  headingText,
  image,
  bottomBackgroundColor,
  bottomTextColor,
  topBackgroundColor,
  topTextColor,
  date,
  ctaLink,
  stretchOnMobile,
  bottomTextColorTablet,
}) {
  return (
    <SmartLink to={ctaLink} className="no-underline">
      <SlideContainer
        key={id}
        className={customClass}
        stretchOnMobile={stretchOnMobile}
        bottomTextColorTablet={bottomTextColorTablet}
        bottomTextColor={bottomTextColor}
      >
        <Top
          className="slide-top-block"
          style={{ background: topBackgroundColor }}
        >
          <Heading
            className="slide-heading-text"
            style={{
              color: topTextColor,
            }}
          >
            {headingText}
          </Heading>
          <Image
            className="slide-image"
            src={image}
            alt={title || headingText}
          />
        </Top>
        <Bottom
          className="slide-bottom-block"
          style={{ background: bottomBackgroundColor }}
        >
          <Title
            className="slide-title-text"
            style={{ color: bottomTextColor }}
          >
            {title}
          </Title>
          <Date className="slide-date-text" style={{ color: bottomTextColor }}>
            {date}
          </Date>
        </Bottom>
      </SlideContainer>
    </SmartLink>
  )
}

export default Slide
