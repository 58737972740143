import React from 'react'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper'
import tw from 'tailwind.macro'
import Slide from './Slide'
import { sm, xl } from '../../../../../tailwind.screens'
import { screen } from '../../../../utils'
import 'swiper/swiper.min.css'

SwiperCore.use([Navigation, Pagination, Autoplay])

const defaultParams = {
  centeredSlides: false,
  navigation: false,
  loop: false,
  autoplay: {
    delay: 5500,
    disableOnInteraction: false,
    pauseOnMouseEnter: true,
  },
  pagination: {
    el: '.swiper-dots-home-slider',
    type: 'bullets',
    clickable: true,
  },
  observer: true,
  observeParents: true,
  watchSlidesVisibility: true,
  slidesPerGroup: 1,
  slidesPerView: 1,
  spaceBetween: 0,
  breakpoints: {
    [parseInt(sm)]: {
      slidesPerGroup: 2,
      slidesPerView: 'auto',
      spaceBetween: 10,
    },
    [parseInt(xl)]: {
      slidesPerGroup: 3,
      slidesPerView: 'auto',
      spaceBetween: 10,
    },
  },
  onAfterInit(swiper) {
    swiper.update()
  },
}

const SwiperDots = styled.div`
  ${tw`
    flex
    justify-center
    flex
    items-center
  `}
  padding: 36px 0 0 0;

  .swiper-pagination-bullet {
    height: 6px;
    width: 6px;
    margin: 0 2px;
    border-radius: 50%;
    background-color: #cdcdcd;
    margin: 0 6px;
    opacity: 1;
    border: 0;

    &.swiper-pagination-bullet-active {
      height: 8px;
      width: 8px;
      background: #77f835;
    }
  }
`

const Container = styled.div`
  background: #000;
  padding: 0 25px;
  padding-bottom: 58px;
  position: relative;

  ${screen('md')} {
    padding-top: 54px;
    padding-bottom: 0;
  }
`
const Inner = styled.div`
  max-width: 1280px;
  ${tw`
    mx-auto
    my-0
  `}
`

const SectionTitle = styled.h3`
  ${tw`
    m-0
    p-0
    uppercase
  `}
  padding: 34px 0 27px 0;
  max-width: 200px;

  ${screen('md')} {
    max-width: 100%;
    padding-bottom: 36px;
    font-size: 24px;
    line-height: 29.05px;
    padding-top: 3px;
  }
`

const getCustomClasses = (slideIndex) => {
  let classes = []

  if (slideIndex === 0) {
    classes.push('desktop-big')
    classes.push('tablet-big')
  } else {
    if (slideIndex % 3 === 0) {
      classes.push('desktop-big')
    } else {
      classes.push('desktop-small')
    }
    if (slideIndex % 2 === 0) {
      classes.push('tablet-big')
    } else {
      classes.push('tablet-small')
    }
  }

  return classes.join(' ')
}

function HomepageSlider({ customParams, items, title }) {
  const instanceParams = {
    ...defaultParams,
    ...customParams,
  }

  if (!items || !items.length) return null

  return (
    <Container>
      <Inner>
        {title && <SectionTitle>{title}</SectionTitle>}
        <Swiper {...instanceParams}>
          {items.map((item, index) => (
            <SwiperSlide key={`slide_${item.id}`} className="homepage-swiper">
              <Slide {...item} customClass={getCustomClasses(index)} />
            </SwiperSlide>
          ))}
        </Swiper>
        <SwiperDots className="swiper-dots-home-slider" />
      </Inner>
    </Container>
  )
}

export default HomepageSlider
