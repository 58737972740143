import React from 'react'
import cx from 'classnames'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { screen } from '../../../utils/index'
import LinkArrow from '../LinkArrow'

const Container = styled.div`
  ${tw`
    flex
    w-full
  `}
`

const Inner = styled.div`
  max-width: 1280px;
  font-family: 'Inter', sans-serif;

  ${tw`
    flex
    lg:flex-row
    w-full
    mx-auto
    my-0
  `}
`

const TextBlock = styled.div`
  ${tw`
    xs:w-full
    lg:w-1/2
    flex
    flex-col
    justify-center
  `}
  padding: 80px 25px 56px 25px;
  color: #000;

  ${screen('xl')} {
    font-size: 50px;
    line-height: 50px;
    padding: 196px 70px 196px 44px;

    &.no-desktop-padding {
      padding-left: 0;
      padding-right: 140px;
    }
  }
`

const ImageBlock = styled.div`
  ${tw`
    relative
    xs:w-full
    lg:w-1/2
  `}

  &.is-reverse {
    ${screen('lg')} {
      justify-content: flex-end;
      display: flex;
    }
  }

  &.is-portrait {
    ${screen('lg')} {
      img {
        padding-top: 80px;
      }
    }
  }

  &:not(.is-reverse) {
    ${screen('lg')} {
      img {
        width: 50vw;
        max-width: 50vw;
      }
    }
  }
`

const Image = styled.img`
  line-height: 0;
  ${tw`
    h-full
    w-full
  `}
  object-fit: cover;

  &.image-portrait {
    object-fit: contain;
    object-position: bottom;
  }

  ${screen('lg')} {
    width: 50vw;
    max-width: 50vw;
  }

  ${screen('xl')} {
    object-fit: cover;
    ${tw`
      absolute
      top-0
      left-0
      bottom-0
      right-0
    `}
  }
`

const MobileImage = styled.img`
  ${screen('lg')} {
    ${tw`
    hidden
  `}
  }
  height: 200px;
  width: 200px;
  border-radius: 50%;
  margin-left: 25px;
  margin-top: 35px;
  object-fit: cover;
  margin-bottom: -60px;
  background: #d9d9d9;
`

const SmallText = styled.h4`
  ${tw`
    uppercase
  `}
  font-size: 18px;
  font-weight: 700;
  line-height: 21.78px;
  padding-bottom: 10px;
  color: ${(props) => props.color || '#000'};

  &:not(.small-text-portrait) {
    word-spacing: 99999px;
    ${screen('md')} {
      word-spacing: unset;
    }
  }

  ${screen('xl')} {
    font-size: 20px;
    line-height: 24.2px;
    word-spacing: unset;
  }
`

const Title = styled.h3`
  font-weight: 700;
  font-size: 35px;
  line-height: 35px;
  padding-bottom: 16px;
  color: ${(props) => props.color || '#000'};

  ${screen('xl')} {
    font-size: 50px;
    line-height: 50px;
  }
`

const Description = styled.p`
  font-size: 20px;
  line-height: 26px;
  font-weight: 600;
  padding-bottom: 24px;
  color: ${(props) => props.color || '#000'};

  ${screen('xl')} {
    padding-bottom: 16px;
    font-size: 22px;
    line-height: 32px;
  }
`

function ImageAndTextBlock({
  smallText,
  title,
  description,
  ctaText,
  ctaLink,
  image,
  background,
  reverse,
  ctaColor,
  titleColor,
  descriptionColor,
  smallTextColor,
  isPortrait,
}) {
  const TextColumn = ({ ...props }) => (
    <TextBlock {...props}>
      {smallText && (
        <SmallText
          color={smallTextColor || '#000'}
          className={isPortrait ? 'small-text-portrait' : null}
        >
          {smallText}
        </SmallText>
      )}
      {title && <Title color={titleColor || '#000'}>{title}</Title>}
      {description && (
        <Description color={descriptionColor || '#000'}>
          {description}
        </Description>
      )}
      {ctaText && ctaLink && (
        <div>
          <LinkArrow text={ctaText} href={ctaLink} color={ctaColor || '#000'} />
        </div>
      )}
    </TextBlock>
  )

  const ImageColumn = () => (
    <ImageBlock
      className={cx({
        ['is-reverse']: reverse,
        ['is-portrait']: isPortrait,
      })}
    >
      {isPortrait && (
        <MobileImage
          src={image}
          alt={title || smallText || description || 'Image'}
        />
      )}
      {image && (
        <Image
          src={image}
          alt={title || smallText || description || 'Image'}
          className={cx({
            ['xs:hidden lg:block']: isPortrait,
            ['image-portrait']: isPortrait,
          })}
        />
      )}
    </ImageBlock>
  )

  return (
    <Container style={{ backgroundColor: background }}>
      <Inner
        className={cx({
          ['flex-col']: reverse,
          ['flex-col-reverse']: !reverse,
        })}
      >
        {reverse ? (
          <>
            <ImageColumn />
            <TextColumn />
          </>
        ) : (
          <>
            <TextColumn className="no-desktop-padding" />
            <ImageColumn />
          </>
        )}
      </Inner>
    </Container>
  )
}

export default ImageAndTextBlock
