import React from 'react'
import styled from 'styled-components'
import ArrowRight from '../../Shapes/ArrowRight'
import { screen } from '../../../utils/index'
import SmartLink from '../SmartLink'

const LinkContainer = styled.div`
  a {
    text-decoration: none;
    display: inline-flex;
    font-size: 20px;
    line-height: 24px;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    color: ${(props) => props.color || '#77f835'};
    padding-right: 13px;
    transition: 0.4s ease all;
    position: relative;

    span {
      display: none;
      ${screen('md')} {
        display: flex;
        margin-left: 11.1px;
      }
    }

    &:hover,
    &:active {
      opacity: 0.6;
    }

    &:after {
      content: '';
      position: absolute;
      height: 1px;
      background: ${(props) => props.color || '#77f835'};
      bottom: -2px;
      left: 0;
      right: 0;
      width: calc(100% - 13px);

      ${screen('md')} {
        right: 50px;
        width: auto;
      }
    }
  }
`

function LinkArrow({ text, href, color, ...props }) {
  return (
    <LinkContainer color={color || '#000'}>
      <SmartLink to={href} {...props}>
        {text}
        <span>
          <ArrowRight />
        </span>
      </SmartLink>
    </LinkContainer>
  )
}

export default LinkArrow
