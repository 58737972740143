import React from 'react'
import { graphql } from 'gatsby'
import HomepageV6 from '../components/Pages/Home/HomepageV6'
import NoTranslation from '../components/Templates/NoTranslation'
import { withPreview } from 'gatsby-source-prismic'

const IndexPage = ({ data, pageContext }) => {
  const page = data.prismicHomepageV6

  if (!page) return <NoTranslation></NoTranslation>
  data = page.data

  const hydratedData = {
    lang: pageContext.locale,
    data,
  }

  return <HomepageV6 {...hydratedData}></HomepageV6>
}

export default withPreview(IndexPage)

export const pageQuery = graphql`
  query HomepageV6ByLocale($locale: String!) {
    prismicHomepageV6(lang: { eq: $locale }) {
      data {
        body {
          __typename
          ... on PrismicHomepageV6BodyHero {
            slice_type
            primary {
              title {
                text
              }
              description {
                html
                text
              }
              cta_text {
                text
              }
              cta_link {
                url
                link_type
              }
            }
          }
          ... on PrismicHomepageV6BodyEcosystem {
            slice_type
            primary {
              image {
                url
              }
              small_text_heading {
                text
              }
              title {
                text
              }
              description {
                text
              }
              projects_list_title {
                text
              }
              cta_text {
                text
              }
              cta_link {
                url
                link_type
              }
            }
            items {
              icon {
                url
              }
              icon_color {
                url
              }
              name {
                text
              }
              link {
                url
                link_type
              }
              background
            }
          }
          ... on PrismicHomepageV6BodyImageandTextblock {
            slice_type
            primary {
              the_image_is_a_portrait
              image {
                url
              }
              background_color
              small_text_heading {
                text
              }
              small_text_color
              title {
                text
              }
              title_color
              description {
                text
              }
              description_color
              cta_text {
                text
              }
              cta_link {
                url
                link_type
              }
              cta_color
              reverse
            }
          }
          ... on PrismicHomepageV6BodySlider {
            slice_type
            primary {
              title {
                text
              }
            }
            items {
              image {
                url
              }
              link {
                url
                link_type
              }
              heading {
                text
              }
              title {
                text
              }
              date {
                text
              }
              top_text_color
              top_background_color
              bottom_text_color
              bottom_text_color_tablet
              bottom_background_color
              stretch_image_on_mobile
            }
          }
        }
      }
    }
  }
`
